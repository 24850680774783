import model from './model';
import { PopulatedMenuClient } from '../../api/PopulatedMenuClient';
import { OLOController } from './oloController';
import type { CartLineItem } from '../../services/cartService';
import { CartService } from '../../services/cartService';
import { state } from '../../states/RootState';
import type { LineItem, Cart } from '@wix/ambassador-ecom-v1-cart/types';
import type { PopulatedMenu } from '../../types/menusTypes';
import { WarmupDataManager } from '../../utils/WarmupDataManager';
import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import { FedopsLogger } from '../../utils/monitoring/FedopsLogger';
import { ModalService } from '../../services/modalService';
import { OperationsClient } from '../../api/operationClient';
import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import type { ItemData } from '../../types/item';
import { SPECS } from '../../appConsts/experiments';
import { getCartItemById } from '../../utils/cartUtils';
import type { IBIReporterService } from '../../services/biReporterService';
import { BIReporterService } from '../../services/biReporterService';
import type { Operation } from '../../types/businessTypes';
import { PersistDataService } from 'root/services/persistDataService';
import { getSiteCurrency } from '../../utils/currency';
import { PriceFormattingConverter } from '@wix/restaurants-olo-operations-client-commons';
import { initDispatchState } from 'root/states/initDispatchState';
import { FulfillmentsClient } from '../../api/fulfillmentsClient';
import { dispatchState } from 'root/states/DispatchState';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'root/api/consts';
import type { SortableMenu } from './panels/MenuSettings/types';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { PopulateMenuIdsByOperationClient } from 'root/api/PopulateMenuIdsByOperationClient';
import { NAVIGATION_TOOLBAR_IDS } from 'root/appConsts/blocksIds';
import { OrdersSettingsService } from 'root/services/ordersSettingsService';
import { runInAction } from 'mobx';
import { truncateMenus } from 'root/utils/menusUtils';
import { menusState } from 'root/states/MenusState';
import { openDispatchModal } from 'root/utils/utils';
import { getTimezoneOffset } from 'root/api/utils/utils';

type ImageSD = {
  url: string;
  id: string;
  height: number;
  width: number;
  altText: string;
};

const reportConductedExperiments = (bi: IBIReporterService, experiments: Experiments) => {
  const value = experiments.all();
  bi.reportOloGenericDebugBiEvent({
    subjectType: 'conductedExperiments',
    value,
  });
};

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const {
    translations,
    httpClient,
    experiments,
    bi,
    fedops,
    environment,
    controllerConfig,
    errorMonitor,
    reportError,
  } = flowAPI;
  const { wixCodeApi, platformAPIs, compId } = controllerConfig;
  const t = translations.t as TFunction;
  const { metaSiteId = '' } = platformAPIs.bi || {};
  const timezone = wixCodeApi.site.timezone || DEFAULT_TIMEZONE;
  const locale = wixCodeApi.site.regionalSettings || DEFAULT_LOCALE;

  const shouldSkipInSSR = environment.isViewer || environment.isPreview;
  const shouldTruncateMenus = experiments.enabled(SPECS.truncateMenus) && shouldSkipInSSR;
  const maxItemsInTruncatedMenu = Number(experiments.get(SPECS.truncateToValue));
  const menuTruncateThreshold = Number(experiments.get(SPECS.truncateFromValue));
  const isOpenItemModalByQueryParams = experiments.enabled(SPECS.openItemModalByQueryParams);

  $widget.onPropsChanged(async (prevProps, nextProps) => {
    if (prevProps.sortedMenus !== nextProps.sortedMenus) {
      const menusOrder = (
        (nextProps.sortedMenus || menusState.sortedMenusDto) as SortableMenu[]
      ).map((sortedMenu) => sortedMenu.id);
      menusState.orderMenus(menusOrder);
    }
  });

  const warmupData = new WarmupDataManager(wixCodeApi.window.warmupData, environment.isSSR);

  const biReporterService = BIReporterService({
    biLogger: bi,
    environment,
    widgetInstanceId: compId,
  });
  const fedopsLogger = new FedopsLogger(
    fedops as FedopsLoggerType,
    metaSiteId,
    state.biReporterService
  );
  const cartService = CartService({
    httpClient,
    fedopsLogger,
    sentry: errorMonitor,
    experiments,
    metaSiteId: platformAPIs.bi?.metaSiteId,
    wixAPI: wixCodeApi,
    biReporterService,
  });

  menusState.setOrdersSettingsService(
    OrdersSettingsService({
      httpClient,
      fedopsLogger,
      sentry: errorMonitor,
      reportError,
      experiments,
    })
  );

  runInAction(() => {
    state.PersistDataService = PersistDataService(
      platformAPIs.storage.session,
      environment.isEditor
    );
    state.biReporterService = biReporterService;

    state.fedopsLogger = fedopsLogger;

    state.CartService = cartService;

    state.ModalService = state.ModalService
      ? state.ModalService
      : new ModalService(wixCodeApi, fedopsLogger, environment.isMobile, state.CartService);
  });

  const oloController = new OLOController($bind, $w, t);

  fedopsLogger.loadOloPageStarted();
  state.biReporterService?.reportRestaurantsUouPageStartedLoadingBiEvent();

  const currency = getSiteCurrency(flowAPI);
  runInAction(() => {
    state.priceFormatter = PriceFormattingConverter.createPriceFormatter(locale, currency);
    state.currency = currency;
  });

  const pageOperationIdPromise = experiments.enabled(SPECS.multiPages)
    ? getPageOperationId(wixCodeApi.site, errorMonitor)
    : undefined;

  reportConductedExperiments(biReporterService, experiments);

  // cart button visible either by experiment, or by `hidden` flag reset in editor (for new users)
  const isCartButtonVisible =
    experiments.enabled(SPECS.floatingCartButton) || !$widget.props.isCartButtonHidden;

  if (!isCartButtonVisible) {
    $bind(NAVIGATION_TOOLBAR_IDS.cartButton, {
      deleted: () => true,
    });
  }

  const menuIdsByOperationPromise = PopulateMenuIdsByOperationClient({
    experiments,
    httpClient,
    fedopsLogger,
    reportError,
    sentry: errorMonitor,
    timezoneOffset: getTimezoneOffset(timezone),
  })
    .getAll(pageOperationIdPromise)
    .then((response) => {
      const menusAvailability = response?.menusAvailability || {};
      menusState.setMenusAvailability(menusAvailability);

      return response;
    });

  const fetchPopulatedMenus = () =>
    PopulatedMenuClient({
      httpClient,
      experiments,
      msid: metaSiteId,
      currency,
      sentry: errorMonitor,
    }).getAll(menuIdsByOperationPromise);

  const fetchOperation = () =>
    new OperationsClient(flowAPI.httpClient).getOperation(pageOperationIdPromise);

  const getMonitoredPopulatedMenuClient = () =>
    getMonitoredApiCall({
      callback: fetchPopulatedMenus,
      fedops: { start: fedopsLogger.fetchMenusDataStarted, end: fedopsLogger.fetchMenusDataEnded },
      reportError,
      onError: () => {
        state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      },
    });

  const getMonitoredOperationClient = () =>
    getMonitoredApiCall({
      callback: fetchOperation,
      fedops: {
        start: fedopsLogger.fetchOperationDataStarted,
        end: fedopsLogger.fetchOperationDataEnded,
      },
      reportError,
      sentry: errorMonitor,
      onError: () => {
        state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      },
    });

  const menusPromise = warmupData
    .manageData<{ data?: PopulatedMenu[]; error?: Error; truncated?: boolean } | undefined>(
      getMonitoredPopulatedMenuClient,
      'populatedMenus',
      pageOperationIdPromise
    )
    .then(async (response) => {
      const { data, error } = response || {};
      const shouldTruncate =
        shouldTruncateMenus &&
        !!data &&
        data.reduce((acc, menu) => acc + menu.size, 0) > menuTruncateThreshold;
      if (data) {
        const menusOrder = (($widget.props.sortedMenus || data) as SortableMenu[] | undefined)?.map(
          (sortedMenu) => sortedMenu.id
        );
        const menus = shouldTruncate ? truncateMenus(data, maxItemsInTruncatedMenu) : data;
        menusState.setMenus(menus, menusOrder);
      }
      return { data, error, truncated: shouldTruncate };
    });

  const operationPromise = warmupData
    .manageData<{ data?: Operation; error?: Error } | undefined>(
      getMonitoredOperationClient,
      'operation',
      pageOperationIdPromise
    )
    .then((response) => response?.data!);

  const cartPromise = state.CartService?.getCurrentCart();

  const initDispatchStatePromise = new Promise<void>(async (resolve) => {
    const operationId = (await pageOperationIdPromise) || (await operationPromise)?.id;
    const fulfillmentClient = new FulfillmentsClient(httpClient, operationId);
    const persistedState = state.PersistDataService?.getDispatchState();
    const fetchDispatchState = async () =>
      initDispatchState(
        fulfillmentClient,
        operationPromise,
        timezone,
        cartPromise,
        persistedState,
        fedopsLogger,
        reportError,
        errorMonitor
      );
    dispatchState.init(await fetchDispatchState());
    !environment.isSSR &&
      menusState.updateAvailabilityStatus(
        operationId,
        dispatchState.dispatchInfo,
        dispatchState.selectedDispatchType
      );
    resolve();
  });

  const initCartDetails = (cart?: Cart) => {
    if (!cart) {
      return;
    }
    const { lineItems } = cart;
    lineItems && setCartLineItems({ lineItems });
  };

  const setCartLineItems = ({ lineItems = [] }: { lineItems: LineItem[] }) => {
    runInAction(() => {
      state.cartLineItems = new Map();
      lineItems.forEach((cartItem) => {
        const { quantity, catalogReference } = cartItem;
        const { options, catalogItemId: itemId } = catalogReference ?? {};
        const { menuId, menuSectionId } = options ?? {};
        const cartLineItemsKey = `${menuId}_${menuSectionId}_${itemId}`;
        const cartLineItems = state.cartLineItems.get(cartLineItemsKey) ?? [];
        return state.cartLineItems.set(cartLineItemsKey as string, [
          ...cartLineItems,
          {
            id: cartItem.id ?? '',
            catalogItemId: itemId as string,
            quantity,
            options,
          },
        ]);
      });
    });
  };

  const retryOnEmptyMenus = async (counter = 0) => {
    if (counter < 5) {
      let resolveFn: (data?: PopulatedMenu[]) => void;
      const menusRetryPromise = new Promise<PopulatedMenu[] | undefined>(
        (resolve) => (resolveFn = resolve)
      );

      setTimeout(async () => {
        const { data } = (await getMonitoredPopulatedMenuClient()) || {};
        resolveFn(data);
      }, 2000);

      const menus = (await menusRetryPromise) || [];
      if (!menus?.length) {
        retryOnEmptyMenus(counter + 1);
      } else {
        const menusOrder = (
          ($widget.props.sortedMenus || menus) as SortableMenu[] | undefined
        )?.map((sortedMenu) => sortedMenu.id);
        menusState.setMenus(menus, menusOrder);
      }
    }
  };

  const getItemById = (itemId: string, menus: PopulatedMenu[]) => {
    if (itemId) {
      for (const menu of menus) {
        for (const section of menu.sections) {
          const items = section.items
            ?.filter((item) => item.id === itemId)
            .map((item) => ({ item, sectionId: section.id, menuId: menu.id }));

          if (items?.length) {
            return items;
          }
        }
      }
    }
  };

  const openEditItemModal = async (cartItemId: string, menus: PopulatedMenu[]) => {
    const cartItem = getCartItemById(state.cartLineItems, cartItemId);
    openItemModal({ menus, cartItem });
  };

  const openItemModalByQueryParam = async (menus: PopulatedMenu[]) => {
    const shouldOpenDishModal =
      !!dispatchState.dispatchInfo.address || !dispatchState.hasAvailableDispatches;
    const { itemId, sectionId, menuId } = wixCodeApi.location.query;
    if (itemId && sectionId && menuId) {
      if (shouldOpenDishModal) {
        openItemModal({ itemId, sectionId, menuId, menus });
      } else {
        await openDispatchModal({
          onSave: async ({ dispatchType, dispatchInfo }) => {
            dispatchState.update(dispatchType, dispatchInfo);
            state.CartService?.setShippingDetails(dispatchState.getShippingDetails());
            await menusState.updateAvailabilityStatus(
              state.operation?.id,
              dispatchInfo,
              dispatchType
            );
            openItemModal({ menus, menuId, sectionId, itemId });
          },
          rootState: state,
          dispatchState: dispatchState.state,
        });
      }
    }
  };

  const openItemModal = async ({
    menus,
    menuId,
    sectionId,
    itemId,
    cartItem,
  }: {
    menus: PopulatedMenu[];
    menuId?: string;
    sectionId?: string;
    itemId?: string;
    cartItem?: CartLineItem;
  }) => {
    const [menuItem] =
      (cartItem?.catalogItemId
        ? getItemById(cartItem?.catalogItemId, menus)
        : getItemById(itemId ?? '', menus)?.filter(
            (item) => item.menuId === menuId && item.sectionId === sectionId
          )) ?? [];
    const isMenuOfItemAvailable = menusState.getMenu(menuItem.menuId)?.isAvailable ?? true;
    state.ModalService?.openDishModal({
      item: menuItem.item as ItemData,
      cartService: state.CartService,
      operationId: state.operation?.id,
      canAcceptOrders: dispatchState.availableDispatchTypes.length > 0,
      sectionId: menuItem.sectionId,
      menuId: menuItem.menuId,
      cartItem,
      isMenuOfItemAvailable,
      openItemModalByQueryParams: isOpenItemModalByQueryParams,
    });
  };

  const retryFetchingMenusIfNeeded = (menus: PopulatedMenu[]) => {
    if (!menus.length) {
      // TODO: set menus empty state
      !environment.isViewer && retryOnEmptyMenus(0);
    }
  };

  const registerOnCartChangeEvent = () => {
    state.CartService?.onChange(async (_lineItems: LineItem[]) => {
      // menuId & sectionId not exists in lineItems argument
      const { lineItems: cartLineItems } = (await state.CartService?.getCurrentCart()) ?? {};
      cartLineItems && setCartLineItems({ lineItems: cartLineItems });
    });
  };

  const openItemModalsIfNeeded = async (menus: PopulatedMenu[]) => {
    isOpenItemModalByQueryParams && (await openItemModalByQueryParam(menus));

    const editCartItemEnabled = experiments.enabled(SPECS.editCartItem);
    const { cartItemId = undefined } = editCartItemEnabled
      ? controllerConfig.wixCodeApi.location.query
      : {};

    if (cartItemId) {
      openEditItemModal(cartItemId, menus);
    }
  };

  const convertMenusToImageSD = (menus: PopulatedMenu[]): ImageSD[] =>
    menus
      .flatMap((menu) =>
        menu.sections.flatMap((section) =>
          section.items?.map((item) => ({
            url: item.image?.url ?? '',
            id: item.id,
            height: item.image?.height ?? 0,
            width: item.image?.width ?? 0,
            altText: item.image?.altText ?? item.name,
          }))
        )
      )
      .filter(Boolean) as ImageSD[];

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      oloController.setNavigationMenu();
      try {
        const [menusData, operation, cart] = await Promise.all([
          menusPromise,
          operationPromise,
          cartPromise,
        ]);

        const { data: menus = [], truncated } = menusData || {};
        retryFetchingMenusIfNeeded(menusState.sortedMenusDto);

        initCartDetails(cart);

        runInAction(() => {
          state.operation = operation;
        });

        await initDispatchStatePromise;

        // Fetch all menus after SSR & hydration
        if (!environment.isSSR) {
          if (truncated) {
            setTimeout(() => menusState.setMenus(menus), 1500);
            if (experiments.enabled(SPECS.seo)) {
              const itemData = { images: convertMenusToImageSD(menus) };
              wixCodeApi.seo.renderSEOTags({ itemType: 'IMAGES_COMPONENT', itemData });
            }
          }
          registerOnCartChangeEvent();
          await openItemModalsIfNeeded(menus);
        }

        setTimeout(() => {
          if (!environment.isSSR) {
            dispatchState.setIsLoading(false);
          }
        }, 0);

        const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser?.loggedIn;
        const { utm_source: refferalInfo = undefined } = wixCodeApi.location.query ?? {};

        state.biReporterService?.reportOloLiveSiteOloPageLoadedBiEvent({
          isMemberLoggedIn,
          menus,
          refferalInfo,
        });

        fedopsLogger.loadOloPageEnded();
        state.biReporterService?.reportRestaurantsUouPageFinishedLoadingBiEvent();
      } catch (e) {
        // TODO: set menus error state
        state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    },
    exports: {},
  };
});
