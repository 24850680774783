import type { PopulatedMenu } from 'root/types/menusTypes';
import { AvailabilityType } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import { DateTime } from 'luxon';
import type {
  MenuAvailability,
  WeeklyAvailability,
} from '@wix/restaurants-olo-operations-client-commons';
import { getWeeklyAvailabilitySummary } from '@wix/restaurants-olo-operations-client-commons';
import type { TFunction } from '@wix/yoshi-flow-editor';

// this method should limit items count on menus according to second parameter
const truncateMenus = (menus: PopulatedMenu[], maxItems: number): PopulatedMenu[] => {
  let itemsLeft = maxItems;
  return menus.map((menu) => {
    return {
      ...menu,
      sections: menu.sections.map((section) => {
        const truncatedSection = { ...section, items: section.items?.slice(0, itemsLeft) };
        itemsLeft -= (section.items || []).length;
        itemsLeft = Math.max(itemsLeft, 0);
        return {
          ...truncatedSection,
          truncated: truncatedSection.items?.length !== (section.items || []).length,
        };
      }),
      size: Math.min(menu.size, maxItems),
      truncated: menu.size > maxItems,
    };
  });
};

const getMenuAvailabilityStatusText = ({
  availability,
  locale,
  timezone,
  hasNextAvailability,
  t,
}: {
  availability: MenuAvailability;
  locale: string;
  timezone: string;
  hasNextAvailability?: boolean;
  t: TFunction;
}): {
  shouldCollapseAvailabilityStatus?: boolean;
  text?: string;
} => {
  if (availability.type === AvailabilityType.TIMESTAMP_RANGES) {
    const availableRanges = availability.timestampRangesOptions?.ranges;
    if (availableRanges && availableRanges.length > 0) {
      const now = DateTime.now().setZone(timezone).valueOf();
      const availableRange = availableRanges.find((range) => {
        const startTime = range.startTime;
        return startTime && startTime.valueOf() > now;
      });

      if (availableRange) {
        const text = formatDateRanges({
          startDate: availableRange.startTime,
          endDate: availableRange.endTime,
          locale,
          timezone,
          hasNextAvailability,
          t,
        });

        return { text };
      } else {
        return { shouldCollapseAvailabilityStatus: true };
      }
    }
  } else if (availability.type === AvailabilityType.WEEKLY_SCHEDULE) {
    const weeklyAvailability = availability.weeklyScheduleOptions?.weeklyAvailability;
    if (weeklyAvailability) {
      const weeklyAvailabilitySummary = getWeeklyAvailabilitySummaryText({
        availability: weeklyAvailability,
        locale,
        t,
      });
      const key = hasNextAvailability
        ? 'menu_olo.menuAvailabilityStatus.weeklySchedule.text'
        : 'menu_olo.menuAvailabilityStatus.weeklySchedule.noNextAvailability.text';
      const text = weeklyAvailabilitySummary ? t(key, { weeklyAvailabilitySummary }) : undefined;

      return { shouldCollapseAvailabilityStatus: !text, text };
    }
  }

  return { shouldCollapseAvailabilityStatus: true };
};

const formatDateRanges = ({
  startDate,
  endDate,
  locale,
  timezone,
  hasNextAvailability,
  t,
}: {
  startDate?: Date;
  endDate?: Date;
  locale: string;
  timezone: string;
  hasNextAvailability?: boolean;
  t: TFunction;
}) => {
  let key = '';
  const formattedStartDate = startDate?.toLocaleString(locale, {
    month: 'long',
    day: 'numeric',
    timeZone: timezone,
  });
  const formattedEndDate = endDate?.toLocaleString(locale, {
    month: 'long',
    day: 'numeric',
    timeZone: timezone,
  });

  if (formattedStartDate !== formattedEndDate) {
    key = hasNextAvailability
      ? 'menu_olo.menuAvailabilityStatus.dateRange.text'
      : 'menu_olo.menuAvailabilityStatus.dateRange.noNextAvailability.text';
  } else {
    key = hasNextAvailability
      ? 'menu_olo.menuAvailabilityStatus.oneDate.text'
      : 'menu_olo.menuAvailabilityStatus.oneDate.noNextAvailability.text';
  }

  return t(key, {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  });
};

const getWeeklyAvailabilitySummaryText = ({
  availability,
  locale,
  t,
}: {
  availability?: WeeklyAvailability;
  locale: string;
  t: TFunction;
}) => {
  const weeklyAvailabilitySummary =
    availability &&
    getWeeklyAvailabilitySummary({
      availability,
      dayNameProvider: (day) => t(`menu_olo.menuAvailabilityStatus.${day}.short`),
      languageCode: locale,
    });
  if (!weeklyAvailabilitySummary) {
    return '';
  } else {
    const availabilityByDays: { [key: string]: string[] } = {};

    weeklyAvailabilitySummary.forEach((timeSlots, days) => {
      const slots = timeSlots.map((slot) => slot.timeSlot);

      if (slots.length > 0) {
        availabilityByDays[days] = slots;
      }
    });

    return Object.entries(availabilityByDays)
      .map(([days, timeSlots]) => {
        return `${days}: ${timeSlots.join(', ')}`;
      })
      .join('; ');
  }
};

export { truncateMenus, getMenuAvailabilityStatusText, getWeeklyAvailabilitySummaryText };
