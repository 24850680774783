import { makeAutoObservable, toJS, runInAction } from 'mobx';
import type { PopulatedMenu } from 'root/types/menusTypes';
import { AvailabilityStatus } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import type { Address } from '@wix/ambassador-restaurants-operations-v1-operation/types';
import { getSortedArrayByIds, sortMenusByAvailability } from 'root/utils/utils';
import type { IOrdersSettingsService } from 'root/services/ordersSettingsService';
import { DispatchType } from 'root/types/businessTypes';
import type { DispatchInfo, TimeSlotsPerMenu } from 'root/types/businessTypes';
import { dispatchState } from 'root/states/DispatchState';
import type { MenuAvailability } from '@wix/restaurants-olo-operations-client-commons';
import { getMenuAvailabilityStatusText } from 'root/utils/menusUtils';
import type { TFunction } from '@wix/yoshi-flow-editor';

export class MenuState {
  menu: PopulatedMenu;
  constructor(menu: PopulatedMenu) {
    this.menu = menu;
    makeAutoObservable(this);
  }

  get isAvailable() {
    const availabilityStatus = menusState.availabilityStatus[this.menu.id];
    return availabilityStatus !== AvailabilityStatus.UNAVAILABLE;
  }

  get availability() {
    return menusState.menusAvailability[this.menu.id];
  }

  get menuDto() {
    return {
      _id: this.menu.id,
      ...toJS(this.menu),
    };
  }

  get length() {
    return this.menu.size;
  }

  get nextAvailableTimeslot() {
    const timeSlotsPerMenu = menusState.timeSlotsPerMenu[this.menu.id];
    return timeSlotsPerMenu?.[dispatchState.selectedDispatchType.toLowerCase()];
  }

  getAvailabilityStatus({
    locale,
    timezone,
    t,
  }: {
    locale: string;
    timezone: string;
    t: TFunction;
  }) {
    return !this.isAvailable
      ? getMenuAvailabilityStatusText({
          availability: this.availability || {},
          locale,
          timezone,
          hasNextAvailability: !!this?.nextAvailableTimeslot,
          t,
        })
      : {};
  }
}

class MenusState {
  private menusMap: Map<string, MenuState> = new Map();
  menus: MenuState[] = [];
  menusOrder: string[] = [];
  availabilityStatus: Record<string, AvailabilityStatus> = {};
  timeSlotsPerMenu: TimeSlotsPerMenu = {};
  menusAvailability: Record<string, MenuAvailability> = {};
  hasError = false;
  private ordersSettingsService?: IOrdersSettingsService;

  constructor() {
    makeAutoObservable(this);
  }

  setMenus(menus: PopulatedMenu[], menusOrder?: string[]) {
    runInAction(() => {
      this.menus = menus.map((menu) => new MenuState(menu));
      if (menusOrder) {
        this.menusOrder = menusOrder;
      }
      this.menusMap = this.menus.reduce((acc, menuState) => {
        acc.set(menuState.menuDto.id, menuState);
        return acc;
      }, new Map<string, MenuState>());
    });
  }

  getMenu(menuId: string) {
    return this.menusMap.get(menuId);
  }

  setOrdersSettingsService(ordersSettingsService: IOrdersSettingsService) {
    this.ordersSettingsService = ordersSettingsService;
  }

  setMenusAvailability(menusAvailability: Record<string, MenuAvailability>) {
    this.menusAvailability = menusAvailability;
  }

  async updateFirstAvailableTimeSlotsForMenus(operationId?: string, deliveryAddress?: Address) {
    return this.ordersSettingsService
      ?.fetchFirstAvailableTimeSlotsForMenus({
        operationId,
        deliveryAddress,
      })
      .then((timeSlotsPerMenu) => {
        runInAction(() => {
          this.timeSlotsPerMenu = timeSlotsPerMenu;
        });
      });
  }

  setFirstAvailableTimeSlotsForMenus(timeSlotsPerMenu: TimeSlotsPerMenu) {
    this.timeSlotsPerMenu = timeSlotsPerMenu;
  }

  async updateAvailabilityStatus(
    operationId: string | undefined,
    dispatchInfo: DispatchInfo,
    dispatchType: DispatchType
  ) {
    const menuAvailabilityPromise = this.ordersSettingsService
      ?.fetchMenusAvailabilityStatusByDispatchInfo({
        operationId,
        dispatchInfo,
      })
      .then((menusAvailabilityStatus) => {
        runInAction(() => {
          this.availabilityStatus = menusAvailabilityStatus;
        });
      });

    const deliveryAddress =
      dispatchType === DispatchType.DELIVERY ? dispatchInfo.address : undefined;
    const timeSlotsForMenusPromise = this.updateFirstAvailableTimeSlotsForMenus(
      operationId,
      deliveryAddress
    );
    await Promise.all([menuAvailabilityPromise, timeSlotsForMenusPromise]);
  }

  setAvailabilityStatus(availabilityStatus: Record<string, AvailabilityStatus>) {
    this.availabilityStatus = availabilityStatus;
  }

  get unsortedMenusDto() {
    return this.menus.map((menu: MenuState) => menu.menuDto);
  }

  get sortedMenusDto() {
    const sortedByOrderMenusDto = getSortedArrayByIds(this.unsortedMenusDto, this.menusOrder);
    return sortMenusByAvailability(sortedByOrderMenusDto, this.availabilityStatus);
  }

  get hasAtLeastOneMenuWithSections() {
    return this.unsortedMenusDto.some((menu) => menu.sectionIds.length > 0);
  }

  get isEmpty() {
    return this.unsortedMenusDto.length === 0;
  }

  orderMenus(menusOrder: string[] = []) {
    this.menusOrder = menusOrder;
  }

  setHasError(hasError: boolean) {
    this.hasError = hasError;
  }
}

const menusState = new MenusState();

export { menusState };
